.ftr-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    object-fit: scale-down;
    align-items: center;
    align-content: center;
    justify-content: center;}

.ftr__img__wrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  object-fit: scale-down;
  align-items: center;
  justify-items: center;
  vertical-align: middle;
}
.ftr__logo__img {
  position: relative;
  display: flex;
  width:100%;
  max-width: 100%;
  align-self: center;
  justify-self: center;
  object-fit: scale-down;
  vertical-align: middle;
}


@media screen and (max-width: 960px) {
  .ftr__logo__img {
    max-height: 80px;
  }
}

@media screen and (max-width: 768px) {
  .ftr__logo__img {
    max-height: 60px;
  }
}