.hdr-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    object-fit: contain;
}

.hdr__img__wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  object-fit: scale-down;
}
.hdr__logo__img {
  position: relative;
  display: flex;
  width: 65%;
  max-width: 65%;
  height: 100%;
  max-height: 100%;
  object-fit: scale-down;
  align-self: center;
}
.hdr__team__img {
  display: flex;
  position: relative;
  width: 45%;
  max-width: 45%;
  height: 100%;
  max-height: 100%;
  align-self: center;
  object-fit: scale-down;
}

.team_rdmap_container {
    display: 'flex';
    width: '80%';
    max-width: '80%';
    align-items: 'center';
    object-fit: contain;
}

  @media screen and (max-width: 960px) {
    .summary-container > h1 {
      font-size: 36px;
      /*margin-top: -150px;*/
      align-self: center;
      text-align: center;
    }
    .team_rdmap_container {
      width: '100%';
      max-width: '100%';
    }
  }
  
  @media screen and (max-width: 768px) {
    .summary-container > h1 {
      font-size: 24px;
      /*margin-top: -100px;*/
      align-self: center;
    }
  
    .summary-container > p {
      font-size: 16px;
      align-self: center;
      text-align: center;
    }
    .team_rdmap_container {
      width: '100%';
      max-width: '100%';
    }
}