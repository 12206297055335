.teamcards {
  padding: 4rem;
  padding-top: 5%;
}

.teamcards > h1 {
    text-align: center;
    font-size: 55px;
    color: #fff;
    align-self: center;
    align-content: center;
    cursor: text;
}

.team__cards__wrapper > h2 {
  text-align: center;
  font-size: 55px;
  color: #fff;
  cursor: text;
}
.team__cards__container {
  display: flex;
  flex-flow: row;
  flex-direction: row;
  align-items: center;
  align-self: center;
  vertical-align: middle;
  /*max-width: 1120px;*/
  width: 100%;
  /*margin: 1 auto;*/
}

.team__cards__wrapper {
  position: relative;
  align-self: center;
  vertical-align: middle;
  align-items: center;
}

.team__cards__items {
  margin-bottom: 24px;
  align-self: center;
  vertical-align: middle;
}

.team__cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.team__cards__item__link {
  display: flex;
  width: 100%;
  box-shadow: 0 6px 20px #efc54a;
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  flex-direction: row;
  border-color: #efc54a;
  border-style: solid;
  border-width: thick;
  align-content: center;
  align-items: center;
}

.team__cards__item__pic-wrap {
  display: flex;
  position: relative;
  width: 50%;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 1%;
  padding-right: 1%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

/*.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #1f98f4;
  box-sizing: border-box;
}
*/
.team__cards__item__img {
  position: relative;
  display: flex;
  width: 75%;
  max-width: 75%;
  height: 100%;
  max-height: 100%;
  object-fit: scale-down;
  transition: all 0.2s linear;
  align-self: center;
  vertical-align: center;
  flex: 1;
}

{/*}.team__cards__item__img:hover {
  transform: scale(1.1);
}*/}

.team__cards__item__info {
  margin-left: 1%;
  margin-right: 1%;
  display: flex;
  align-content: center;
  align-items: center;
  flex: 1;
}

.team__cards__item__text {
  color: #ffff;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  align-self: center;
  align-content: center;
  cursor: text;
  user-select: text;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .team__cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .team__cards__item {
    margin-bottom: 2rem;
  }
}
@media only screen and (max-width: 960px){
  .team__cards__wrapper > h2 {
    text-align: center;
    font-size: 12px;
    color: #fff;
  }
  .team__cards__item__text {
    color: #ffff;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    align-self: center;
  }
}
@media only screen and (max-width: 750px){
  .team__cards__wrapper > h2 {
    text-align: center;
    font-size: 12px;
    color: #fff;
  }
  .team__cards__item__text {
    color: #ffff;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    align-self: center;
  }
}