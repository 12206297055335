.summary-container{
    /*height: 100vh;*/
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
    flex: 1;
    padding-top: 5%;
    padding-bottom: 1%;
}

.summary-container > h1 {
    color: #fff;
    font-size: 40px;
    align-self: center;
    text-align: center;
    cursor: text;
    user-select: text;
  }
  
.summary-container > p {
    margin-top: 36px;
    color: #fff;
    font-size: 22px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
    text-align: center;
    align-self: center;
    width: 65%;
    cursor: text;
    user-select: text;
  }
  @media screen and (max-width: 960px) {
    .summary-container > h1 {
      font-size: 36px;
      /*margin-top: -150px;*/
      align-self: center;
      text-align: center;
    }
  }
  
  @media screen and (max-width: 768px) {
    .summary-container > h1 {
      font-size: 24px;
      /*margin-top: -100px;*/
      align-self: center;
    }
  
    .summary-container > p {
      font-size: 16px;
      align-self: center;
      text-align: center;
    }
}